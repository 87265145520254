import React, { useState, useRef } from "react";
import "./accordion.css";

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef();

  return (
    <div className="accordion_content" onClick={() => setIsOpen(!isOpen)}>
      <div className="accordion_title">
        <p>{props.title}</p>
        <span>{isOpen ? "-" : "+"}</span>
      </div>
      <div
        ref={contentRef}
        className="main_content"
        style={
          isOpen
            ? { height: contentRef.current.scrollHeight + "px" }
            : {
                height: "0px",
              }
        }
      >
        {props.children}
      </div>
    </div>
  );
}

export default Accordion;
