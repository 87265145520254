import React from "react";
import { Link } from "react-router-dom";
import "./not_found.css";
import { ScrollToTop } from "./../../components";

const NotFoundPage = () => {
  return (
    <>
      <section className="notFoundSection">
        <div className="container">
          <div className="notFoundWrapper">
            <h1>4😞4</h1>
            <h3>Page Not Found!</h3>
            <p>
              We're sorry, the page you requested could not be found. <br />
              Please go back to the <Link to="/">homepage</Link>
            </p>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default NotFoundPage;
